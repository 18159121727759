import prakash from '../assets/pictures/Prakash.webp'
//
const ravena ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Achievements/Raveena+A_+Civil.webp"

const swayam ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/swayam.webp"
const robotics ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/robotics.webp"
const placement="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/placement.webp"

const dpm ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Default_profile+/boy.webp"
const dpf ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Default_profile+/girl.webp"
const cover ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Cover/civil_banner.webp"
const slide1="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Industry_interaction/intern1.webp"
const slide2="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Industry_interaction/inter2.webp"
const slide3="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Industry_interaction/intern3.webp"



const leela ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/leela.webp"
const subramani="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+C.Subramanian-1.webp"
const vijaya="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+C.Vijayaprabha-1.webp"
const vinoth="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+R.Vinothkumar-1.webp"
const kavitha="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+S.M.Kavitha-1.webp"
const sumathy="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+SR+Sumathy-1.webp"
const venkat="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+Venkatesh+Baskaran-1.webp"
const mani="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Prof.R.MANIGANDAN.webp"
const tamizh="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+Senthamizhan+(1)-1.webp"
const ambikapathi="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+Ambikapathi.A-1.jpg"
const appusriram="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+Appu+Sriram-1.jpg"
const arune="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Civil+ARUN.E-1.jpg"
const jeyashankari="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/civil_jeyashankari.webp"

const raja ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_rajasekar.webp"
const kathir="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_kathiresan.webp"
const thyagu="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_thyagarajan.webp"
const selva="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_selvaraj.webp"
const muthu ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_muthuramalingam.webp"
const murugesan="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_murugesan.webp"
const veenus="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Supporting_fac/civil_veenusrani.webp"
const gayathiri="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Faculties/Teaching_fac/Gayathri_civil_fac-1.webp"

const computer ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Computer+Lab.webp" 
const concrete  ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Concrete+lab.webp"
const corrosion ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Corrosion+Lab.webp"
const ug ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Environmental+Engg+Lab.webp"
const pg ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/PG+Environmental+Lab.webp"
const sm  ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/SM+Lab.webp"
const soil ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Soil+Lab.webp"
const structure ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Structural+Lab.webp"
const survey ="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Lab_Facilities/Survey+Lab.webp" 

const nptl_cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Achievements/NPTEL_certificate.webp"
const gate_cover="https://accet-site-media-trial.s3.ap-northeast-1.amazonaws.com/Departments/Civil/Achievements/gate_img.webp"

const civ_dept_menu = [
  {
    id: "1",
    path: "/civildept",
    link: "About the Department",
    arl: "l",
  },
  {
    id: "2",
    path: "/civildept/civil-peo",
    link: "Programme Objectives & Outcomes",
    arl: "l",
  },
  {
    id: "3",
    path: "/civildept/civil-faculties",
    link: "Faculty",
    arl: "l",
  },
  {
    id: "4",
    path: "/civildept/civil-lab",
    link: "Laboratory Facilities",
    arl: "l",
  },
  {
    id: "5",
    path: "/civildept/civil-research",
    link: "Research and Publications",
    arl: "l",
  },
  {
    id: "6",
    path: "/civildept/civil-placement",
    link: "Placement Activities",
    arl: "l",
  },
  {
    id: "7",
    path: "/civildept/civil-library",
    link: "Department Library",
    arl: "l",
  },
  {
    id: "8",
    path: "/civildept/civil-dept-magazine",
    link: "Magazine and Newsletter",
    arl: "l",
  },
  // {
  //   id: "9",
  //   path: "#",
  //   link: "Activities",
  //   arl:'l'
  // },
  {
    id: "10",
    path: "/civildept/civil-awards",
    link: "Achievements",
    arl:'l'
  },
  {
    id: "11",
    path: "/civildept/civil-internal-revenue",
    link: "Internal Revenue Generation",
    arl: "l",
  },
  {
    id: "12",
    path: "/civildept/civil-dept-indus",
    link: "Industry Interaction",
    arl:'l'
  },
];

const civ_fac_profiles = [
  {
    id: "6",
    cover: cover,
    dp: subramani,
    name: "Dr.C.Subramanian",
    Designation: "Professor",
    mailid: "csk285@gmail.com",
    pdf: "https://drive.google.com/file/d/1reOgq2O5xQzEsPK_cgvN6UhCgrSYKMYt/view?usp=drive_link",
  },
  {
    id: "9",
    cover: cover,
    dp: sumathy,
    name: "Dr SR Sumathy",
    Designation: "Associate Professor",
    mailid: "srsumathy@accetedu.in",
    pdf: "https://drive.google.com/file/d/1T6Jiwq-TywClE-EZ1gr0UwG2F3ck9ks-/view?usp=drive_link",
  },
  {
    id: "15",
    cover: cover,
    dp: prakash,
    name: "Dr. R. PRAKASH",
    Designation: "Associate Professor",
    mailid: "rprakash024@gmail.com",
    pdf: "https://drive.google.com/file/d/1Qpd11Jj8NZ3XVCGbuYklMtJ909iRR9mF/view?usp=sharing",
  },
  {
    id: "7",
    cover: cover,
    dp: vijaya,
    name: "Dr.C.Vijayaprabha",
    Designation: "Associate Professor",
    mailid: "vijayaprabha.struct@gmail.com",
    pdf: "https://drive.google.com/file/d/1JaVtmO9lL1tMx-AiOrt6OW5s_1h1dQ7Y/view?usp=drive_link",
  },
  {
    id: "8",
    cover: cover,
    dp: kavitha,
    name: "Dr.S.M.Kavitha",
    Designation: "Associate Professor",
    mailid: "smkavitha@accetedu.in",
    pdf: "https://drive.google.com/file/d/1CN75Jy44sBNcD3zovqYjKMTT-nAT8yqj/view?usp=sharing",
  },
  
  {
    id: "1",
    cover: cover,
    dp: leela,
    name: "Dr.A.Leelavathi",
    Designation: "Assistant Professor",
    mailid: "leelaaccet2007@gmail.com",
    pdf: "https://drive.google.com/file/d/19E3dQNGUdMENQp4p6EyeA2GpwlD1rJAW/view?usp=drive_link",
  },
  // {
    {
      id: "5",
      cover: cover,
      dp: venkat,
      name: "Venkatesh B",
      Designation: "Assistant Professor",
      mailid: "venkatesh@gcetly.ac.in",
      pdf: "https://drive.google.com/file/d/1mepcWTb4EsJf7fFTMs7S4QBf1t9yDJ-f/view?usp=drive_link",
    },
  //   id: "2",
  //   cover: cover,
  //   dp: tamizh,
  //   name: "Senthamizhan M",
  //   Designation: "Assistant Professor(Consolidated Pay)",
  //   mailid: "tamizhanirtt@gmail.com",
  //   pdf: "https://drive.google.com/file/d/1sGVV5DEdPB0yVdtoquz4VQ6idq8l3JEC/view?usp=drive_link",
  // },
  {
    id: "3",
    cover: cover,
    dp: mani,
    name: "R.Manigandan",
    Designation: "Assistant Professor(Adhoc)",
    mailid: "manigandanabm@gmail.com",
    pdf: "https://drive.google.com/file/d/1F3pYXr90rYHYqkrTK7EJJ8ucdWTMYDxu/view?usp=drive_link",
  },
  {
    id: "4",
    cover: cover,
    dp: vinoth,
    name: "R.Vinothkumar",
    Designation: "Assistant Professor(Adhoc)",
    mailid: "vinothrvkce@gmail.com",
    pdf: "https://drive.google.com/file/d/1aM6O4cKxCGbSstY4XhUOoQtoqGfuhOu_/view?usp=drive_link",
  },
  {
    id: "10",
    cover: cover,
    dp: ambikapathi,
    name: "A. Ambikapathi",
    Designation: "Assistant Professor",
    mailid: "ambipathi@gmail.com",
    pdf: "https://drive.google.com/file/d/1tbL1tvVV1YI-OD3Ql-f7a2XVXXl8jYQ5/view?usp=drive_link",
  },
  {
    id: "11",
    cover: cover,
    dp: arune,
    name: "Arun E",
    Designation: "Assistant Professor",
    mailid: "arune1811@gmail.com",
    pdf: "https://drive.google.com/file/d/1ERZGvFwBjop_gfe8li4y14FR9rxQD1bu/view?usp=drive_link",
  },
  {
    id: "12",
    cover: cover,
    dp: appusriram,
    name: "Appu Sriram M",
    Designation: "Assistant Professor",
    mailid: "appusriramm@accet.ac.in",
    pdf: "https://drive.google.com/file/d/1hRLU2SCEJj0rc7SHgafsYvIQL5u0fKSQ/view?usp=drive_link",
  },
  {
    id: "13",
    cover: cover,
    dp: jeyashankari,
    name: "K.JEYASHANKARI",
    Designation: "Assistant Professor(Contract Basis)",
    mailid: "jeys.engg@gmail.com",
    pdf: "https://drive.google.com/file/d/1WpDzj0qu7KL49qKNP0nvV4SNQ3GPuz2K/view?usp=sharing",
  },
  {
    id: "14",
    cover: cover,
    dp: gayathiri,
    name: "M.GAYATHRI",
    Designation: "Assistant Professor",
    mailid: "gayathri@accet.ac.in",
    pdf: "https://drive.google.com/file/d/13GAOVduo48ow1DblMm7GifRJhkD9gp3t/view?usp=sharing",
  },

  
];
const civ_sup_profiles = [
  {
    id: "8",
    cover: cover,
    dp: veenus,
    name: "J.Veenus Rani",
    Designation: "Junior Draughting Officer",
    mailid: "veenusrani22@gmail.com",
    pdf: "https://drive.google.com/file/d/1ArGovhNL_iXvJqQ_ar1gkIpkjPSBhOFy/view?usp=drive_link",
  },
  {
    id: "5",
    cover: cover,
    dp: selva,
    name: "C.Selvaraj",
    Designation: "Lab Assistant (Sl-Gr)",
    mailid: "selvarajc1971@gmail.com",
    pdf: "https://drive.google.com/file/d/1q88hCWtv3g_k5S9TR2avLxWMwdorJu5T/view?usp=drive_link",
  },
  {
    id: "6",
    cover: cover,
    dp: muthu,
    name: "C.Muthuramalingam",
    Designation: "Lab Assistant (Sl-Gr)",
    mailid: "",
    pdf: "https://drive.google.com/file/d/19V56uEx3rQ6W13MG32Ni3-8R5kl3xkpi/view?usp=drive_link",
  },
  {
    id: "1",
    cover: cover,
    dp: raja,
    name: "R.Rajasekar",
    Designation: "Mechanic Grade II",
    mailid: "rajasekaraccet15@gmail.com",
    pdf: "https://drive.google.com/file/d/1SbntNfPPQlaD5bFw5_yzOleqRzMSbZ8f/view?usp=drive_link",
  },
  {
    id: "7",
    cover: cover,
    dp: murugesan,
    name: "P.K.Murugesan",
    Designation: "Lab Assistant",
    mailid: "easank.81@gmail.com",
    pdf: "https://drive.google.com/file/d/1cOzrwf6JxNoojml-CwCxlbPwb6JgcFf4/view?usp=drive_link",
  },
  {
    id: "2",
    cover: cover,
    dp: kathir,
    name: "E.Kathiresan",
    Designation: "Lab Assistantr",
    mailid: "kathiresane2777@gmail.com",
    pdf: "https://drive.google.com/file/d/1Q0KvvXoWDjbhCCEWMwTO9b2H78b8ZFf4/view?usp=drive_link",
  },
  {
    id: "4",
    cover: cover,
    dp: thyagu,
    name: "K.Thiyagarajan",
    Designation: "Cleaner",
    mailid: "",
    pdf: "https://drive.google.com/file/d/13-HPV-MSMtpnhdNRVKvg4t5b_seeqfQd/view?usp=drive_link",
  },
  {
    id: "3",
    cover: cover,
    dp: dpm,
    name: "M.Ramakirshnan",
    Designation: "Cleaner",
    mailid: "ramakirshnan0602@gmail.com",
    pdf: "https://drive.google.com/file/d/139Tc3lgMnCBKkIJ_8GJsnlfQ_DoKQ38b/view?usp=drive_link",
  },
];

const civ_vision = {
  id: "1",
  visiondata:
    "To offer novel and economical solutions for the complex and complicated problems arise in Civil Engineering Industry and to play a significant role in developing the infrastructure in our country. To provide a creative environment for learners to attain high level of excellence in the Civil Engineering Industry.",
};
const civ_mission = [
  {
    id: "1",
    mission:
      "To create, disseminate and integrate in depth knowledge in Civil Engineering among Graduates and to provide a dynamic learning environment that emphasizes open-ended design, problem-solving skills, team work, communication, and leadership skills which in turn enables the betterment of human society.",
  },
  {
    id: "2",
    mission:
      "To produce highly competent Civil Engineering professionals with innovative thinking and ethical standards.",
  },
  {
    id: "3",
    mission:
      "To promote quality education, research and consultancy for industrial and societal needs.",
  },
  {
    id: "4",
    mission:
      "To provide state-of-the-art resources that contributes to a congenial learning environment.",
  },
];

const civ_peo = [
  {
    id: "1",
    head: "PEO 1",
    house: "left",
    content:
      "To provide students a sound knowledge in mathematical, scientific and engineering fundamentals necessary to formulate, solve and analyze engineering problems related to civil engineering industry and to make them aware of the practical problems and constraints in executing an infrastructure and make them well skilled for the future.",
  },
  {
    id: "2",
    head: "PEO 2",
    house: "right",
    content:
      "To cultivate the habit of continuous learning and to create interest in students to pursue their higher studies and research.",
  },
  {
    id: "3",
    head: "PEO 3",
    house: "left",
    content:
      "To develop the ability of students to apply their civil engineering knowledge in practical problems and arrive with viable and economical solutions to confidently work in industries and to have successful careers.",
  },
  {
    id: "4",
    head: "PEO 4",
    house: "right",
    content:
      "To inculcate the habit of practicing ethical standards in the profession and to make them capable in resolving moral dilemmas arise in their profession.",
  },
];

const civ_outcomes = [
  {
    id: "1",
    point:
      "Engineering knowledge: Apply the knowledge of mathematics, science, engineering fundamentals, and an engineering specialization to the solution of complex engineering problems",
  },
  {
    id: "2",
    point:
      "Problem analysis: Identify, formulate, review research literature, and analyse complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.",
  },
  {
    id: "3",
    point:
      "Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.",
  },
  {
    id: "4",
    point:
      "Conduct investigations of complex problems: Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions",
  },
  {
    id: "5",
    point:
      "Modern tool usage: Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.",
  },
  {
    id: "6",
    point:
      "The engineer and society: Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice",
  },
  {
    id: "7",
    point:
      "Environment and sustainability: Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.",
  },
  {
    id: "8",
    point:
      "Ethics: Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.",
  },
  {
    id: "9",
    point:
      "Individual and team work: Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings",
  },
  {
    id: "10",
    point:
      "Communication: Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.",
  },
  {
    id: "11",
    point:
      "Project management and finance: Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.",
  },
  {
    id: "12",
    point:
      "Life-long learning: Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change",
  },
];
const civ_spec_outcomes = [
  {
    id: "1",
    point:
      "Prepare Plans and Layouts for any Civil Engineering Structures and apply construction and management principles to supervise and successfully complete the construction Projects as per the client requirements and need with in the stipulated period of time.",
  },
  {
    id: "2",
    point:
      "Analyse and economically design the safe and durable Superstructures and Foundations for Buildings, Bridges, Tower, Highway, Airfield pavement, Environmental Structures, Liquid and Material storage Structures, Water Conveying Systems and many other allied Civil Engineering Structures.",
  },
  {
    id: "3",
    point:
      "Investigate issues pertaining to water treatment, waste management, pollution control and provide sustainable solutions with due concerns on preserving the ecology and the environment",
  },
];

const internal=[
  {
    id:"1",
    top_head:"2021-2022",
    program:"Consultancy Details",
    pdf:"https://drive.google.com/file/d/1VtwOzFIDpM0B2llsMr5TsE2zikdUrxek/view?usp=drive_link",
  },
  {
    id:"2",
    top_head:"2022-2023",
    program:"Consultancy Details",
    pdf:"https://drive.google.com/file/d/1ukat9JsKvhqON9ccTeQeoIAw0fq6-v3n/view?usp=drive_link",
  },
  {
    id:"3",
    top_head:"2022-2023",
    program:"Testing Details",
    pdf:"https://drive.google.com/file/d/19uZA_DKs0auUcs3GpoqR3rKRfQM6KHxM/view?usp=drive_link",
  },
]

const civ_progs = [
  {
    id: "1",
    year: "1952",
    // period:"4 Years",
    top_head: "B.E.",
    program: "Civil Engineering",
    intake: "60",
  },
  {
    id: "2",
    year: "1971",
    // period:"4 Years",
    top_head: "Part time B.E.",
    program: "Civil Engineering",
    intake: "60",
  },
  {
    id: "3",
    year: "2002",
    // period:"4 Years",
    top_head: "M.E.",
    program: "Structural Engineering",
    intake: "18",
  },
  {
    id: "4",
    year: "2002",
    // period:"4 Years",
    top_head: "M.E.",
    program: "Environmental Engineering",
    intake: "18",
  },
];
const civ_labs = [
  {
      'id':'1',
      'heading':"Soil Mechanics Laboratory",
      'pic':soil,
      'content_1':'The Soil Mechanics Laboratory, occupying 120 sq.m., is well-equipped for a wide range of soil tests. It conducts tests on various sample sizes, covering shear strength, consolidation, CBR, grain size distribution, unconfined compressive strength, and compaction. Students learn index and engineering properties determination techniques. Understanding soil properties and safe bearing capacity is essential for construction design. ',
      'content_2':"The lab also offers consultancy services to government and private organizations, generating college revenue. Clients include PWD, TNEB, RDPR Department, Highways Department, Indian Railways, and more. Key equipment includes CBR apparatus, tri-axial compression test setup, electrical oven, direct shear test apparatus, sieve shaker, and soil permeability test tools."
  },
  {
      'id':'2',
      'heading':'Concrete Laboratory',
      'pic':concrete,
      'content_1':'The Concrete Lab within the Civil Department encompasses an area of 159m², inclusive of a dedicated laboratory incharge cabin. This multifaceted facility caters to the practical laboratory requirements of undergraduate students while also serving as a hub for nondestructive testing activities in collaboration with the Civil Engineering Department.',
      'content_2':"Among its significant assets are the 200T Compression Testing Machine, an accelerated curing tank, a rebound hammer, ultra-sonic pulse velocity testing apparatus, bitumen testing instrumentation, and a comprehensive suite of workability measuring equipment. These resources empower students to engage in hands-on learning and provide a foundation for conducting consultancy projects. The lab actively participates in consultancy endeavors spanning mix design, structural analysis, and stability assessments for existing buildings and historical monuments, contributing significantly to research and industry support."
  },
  {
      'id':'3',
      'heading':'Computer Laboratory',
      'pic':computer,
      'content_1':'The Civil Engineering Department houses a robust computer lab, providing students with cutting-edge resources for their academic and practical needs. The CAD lab, equipped with the latest AUTOCAD software, offers in-depth training in 2D and 3D drafting for Civil Engineering drawings, enhancing students skills in analysis, design, and drafting, crucial for their field.',
      'content_2':"With over 30 computers featuring Core-i7, Core-i3, and Pentium IV configurations, the lab ensures a seamless learning experience. Software like STAADPRO, SAP 2000, ETABS, AUTOCAD 2016, and RCDC detailing software supports structural analysis and design consultancy. High-speed internet connectivity (100MBPS), Smart Boards, printers, copiers, projectors, and UPS systems further facilitate a comprehensive teaching and learning environment."
  },
  {
      'id':'4',
      'heading':'Strength of Materials Laboratory',
      'pic':sm,
      'content_1':'The Strength of Materials Lab is a versatile facility catering to extensive material testing under varying loading conditions, such as gradual, sudden, cyclic, impact, axial, and tangential loading. It encompasses tests for bending, tension, compression, shear, and torsion on materials like steel, concrete, and wood.',
      'content_2':"The lab boasts a comprehensive set of equipment, including UTM machines with 30T and 100T capacities, torsion testing apparatus, Izod and Charpy impact testing machines, wood testing equipment, hardness and spring testing machines, a 16 Channel Data Logger, Electronic Digital Extensometer, Screw Driven 10T UTM, extensometers, and dial gauges. These resources enable thorough material analysis, ensuring students gain practical insights into material behavior and properties."
  },
  {
    'id':'5',
    'heading':"Survey Laboratory",
    'pic':survey,
    'content_1':'In the Surveying Laboratory, students gain valuable experience in collecting and processing field data, honing their skills in landscape measurement and analysis. This dynamic learning environment provides access to a wide range of surveying instruments, both traditional and modern.',
    'content_2':" These instruments include Total Stations, Digital Theodolites, Digital Levels, Digital Planimeters, Theodolites, Dumpy Levels, Leveling Staffs, Prismatic Compasses, Plane Tables, Arrows, Tapes, Ranging Rods, and Chains. Through practical hands-on exercises, students develop proficiency in surveying techniques, equipping them for the practical challenges of land measurement and geospatial analysis in the field."
},
{
    'id':'6',
    'heading':'UG-Environmental Engineering Laboratory',
    'pic':ug,
    'content_1':'The Environmental Engineering Laboratory (UG) occupies 84.8 sq.m. and offers comprehensive facilities for water and wastewater testing. It is a hub for cutting-edge water treatment technologies, renewable energy-based processes, emerging treatment methods, site restoration, and water quality solutions.',
    'content_2':"The lab conducts tests on various sample sizes, encompassing parameters like hardness, chlorides, alkalinity, acidity, sulfates, total solids, total dissolved solids, total suspended solids, pH, turbidity, and dissolved oxygen. Key equipment includes a UV spectrophotometer, COD digester, BOD incubator, Kjeldahl apparatus, Muffle Furnace, and a double distillation unit, supporting a wide range of environmental testing and research activities."
},
{
    'id':'7',
    'heading':'PG-Environmental Engineering Laboratory',
    'pic':pg,
    'content_1':'Spanning 71.3 sq.m., the Environmental Engineering Laboratory (UG) is well-equipped for a wide array of water and wastewater tests. It facilitates chemical, bacteriological, and radiological analyses for various sample types, including drinking water, surface water, wastewater, sediment, air, soil, and hazardous waste.',
    'content_2':"Major equipment comprises an Atomic Absorption Spectrophotometer, Rotary Shaker, Flue Gas Analyzer, Digital Bomb Calorimeter, Flame Photometer, Laminar Flow Chamber, High Volume Sampler, and Ion Chromatography equipment, enabling comprehensive environmental analysis and research activities."
},
{
    'id':'8',
    'heading':'Heavy Testing Laboratory',
    'pic':structure,
    'content_1':'The Heavy Testing Lab is a cutting-edge research facility primarily used by research scholars and postgraduate students. It features high-precision equipment with computer interface capabilities. Notable equipment includes a hollow block machine for concrete block production, a shake table for dynamic studies and seismic simulations,',
    'content_2':"a 16-channel data logger for simultaneous deflection measurements, and a pile testing system for evaluating model piles. Additional resources comprise a Servo Hydraulic UTM (25T capacity), loading frames (50T and 100T capacity), a 5T crane, LVDTs, dial gauges, power packs with hydraulic jacks, and an RC Reaction cell for structural testing."
},
{
  'id':'9',
  'heading':'Corrosion Laboratory',
  'pic':corrosion,
  'content_1':'The Corrosion Engineering Lab focuses on the prevention and control of corrosion damage through scientific and artistic approaches. It conducts corrosion tests to establish mechanisms, define material corrosion resistance, estimate equipment service life, develop corrosion protection processes, and identify critical potential values in various environments.',
  'content_2':"This lab conducts experiments on the corrosion and durability performance of concrete, steel, metals, and alloys under varying conditions. It plays a crucial role in teaching, research, and consultancy activities. Key equipment includes Rapid Chloride Penetration Test (RCPT) Apparatus, Linear Polarization Resistivity Meter, Electro Chemical Impedance Analyzer, Basic Vibration Testing Rig, and a Screw Driven UTM, enabling comprehensive corrosion-related studies."
},
]

const civ_dept_events = [
  {
    id: "1",
    heading: "One Week Training on Robotics",
    "sub-head":
      "Some representative placeholder content for the second slide of the carousel.",
    pic: robotics,
    content_1:
      "Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners" +
      "in leadership qualities so as to achieve better productivity and prosperity for our country.",
    content_2:
      "ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s" +
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong" +
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity.",
  },
  {
    id: "2",
    heading: "Placement Activities",
    "sub-head":
      "Some representative placeholder content for the second slide of the carousel.",
    pic: placement,
    content_1:
      "Our Commitment as a Centre of Engineering Education is to impart Technical Knowledge par excellence, motivate the learners in Research, evolve result-oriented, innovative techniques in Engineering, provide necessary career guidance, and train our learners" +
      "in leadership qualities so as to achieve better productivity and prosperity for our country.",
    content_2:
      "ACGCET, a leader in higher education in engineering and technology, is committed to excellence through the delivery of outstanding undergraduate and graduate education that remains current and responsive to the diverse needs of those it serves. The institution’s" +
      "faculty and staff promote the intellectual, professional, social, and personal development of its students through innovations in learning, scholarship, and creative endeavours. Students, faculty, and staff partner to create strong" +
      "engagement with the local, national, and global communities. The institution dedicates itself to academic vitality, integrity, and diversity.",
  },
];

const civ_awards = [
  {
    id: "9",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1HvXAigB530wWAC78M7j2XOaNu6JelRmF/view?usp=sharing",
    content:
      "Dr. S. R. Sumathy, As the Coordinator of the NPTEL Local Chapter has encouraged more students across colleges to participate in NPTEL courses.",
  },
  {
    id: "6",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/1XH1mUAAL8EJ48JKdN-RFFY5Uqz34F0Ap/view?usp=drive_link",
    content:
      "Dr. C. Vijayaprabha achieved the distinction of being designated as an 'NPTEL Domain Scholar' in the field of Concrete Technology by IIT Madras during the period from July to October 2023, demonstrating her advanced knowledge and expertise in civil engineering.",
  },
  {
    id: "7",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1i9MWj3irHjcpD5_uPAzDdHT8RqndwfGP/view?usp=sharing",
    content:
      "Dr. S. R. Sumathy, As the Nodal Officer for the Academic Bank of Credits has reported that 6,686 marksheets have been published through DigiLocker to date.",
  },
  {
    id: "5",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/1lIQNqnjuMviz1KNEMD_zvqDEQRwnrSQl/view?usp=drive_link",
    content:
      "Dr. C. Vijayaprabha was recognized as an 'NPTEL Discipline Star' in Concrete Technology by IIT Madras for her exceptional performance during the NPTEL course from July to October 2022, showcasing her dedication and expertise in the field of civil engineering.",
  },
  {
    id: "4",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1iwS94tI0WzzlkaNFHhMxT-oTRT8ovjTd/view?usp=drive_link",
    content:
      "Dr. C. Vijayaprabha demonstrated her commitment to learning by earning the 'NPTEL Believer' recognition in the field of Concrete Technology from IIT Madras during the January to April 2022 period through the NPTEL (National Programme on Technology Enhanced Learning) program.",
  },
  {
    id: "3",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/160ufTl6ly5eM-KoyJ2qgcAgAakJBEXos/view?usp=drive_link",
    content:
      "In 2022, Dr. C. Vijayaprabha was honored with the Best Paper Award from the Ramco Institute of Technology for her research titled 'The Effect of Sloshing due to the Dynamic Load in a Liquid Storage Tank with and Without Internal Obstructions' in the field of civil engineering.",
  },
  {
    id: "1",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1oAi1qpg4XlvIKerO8qEKktOTmTceNH1c/view?usp=drive_link",
    content:
      "In 2021, Dr. S. R. Sumathy was awarded the NESIN Award for Best Researcher in Civil Engineering by Science Father, recognizing her exceptional contributions to the field.",
  },
  {
    id: "2",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/1rnXzucT0I21lJqn-QUhqXvN67S4lb1Ke/view?usp=drive_link",
    content:
      "In October 2020, Dr. C. Vijayaprabha received the Research Excellence Award in Civil Engineering, known as the InSc Award 2020, from the Institute of Scholars (InSc), acknowledging her outstanding contributions to the field.",
  },
  {
    id: "8",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1cyFgg57bVBgzilequQRqAvQt6f9EAyva/view?usp=sharing",
    content:
      "Dr. S. R. Sumathy, As the Coordinator for IIRS has motivated students to enroll in the IIRS Outreach e-Learning Education Programme.",
  },
];

const civ_patents = [
  {
    id: "1",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/1XoBHM3ZcFcCX72-rz8Lb49Te5HvjVmqR/view?usp=drive_link",
    content:
      "In 2021, a patent was granted to Dr. C. Vijayaprabha, Mr. A. Ramanan, Dr. K. Aarthi, and Dr. S. R. Sumathy for their innovative method of preparing paver blocks that incorporate copper slag.In 2021, a patent was granted to Dr. C. Vijayaprabha, Mr. A. Ramanan, Dr. K. Aarthi, and Dr. S. R. Sumathy for their innovative method of preparing paver blocks that incorporate copper slag. ",
  },
  {
    id: "2",
    head: "",
    house: "right",
    pdf: "",
    content:
      "Dr. K. Aarthi, Dr. C. Vijayaprabha, Dr. A. Leelavathi, and Dr. S. R. Sumathy published a method for the preparation of unburnt bricks that incorporate sewage sludge and granite sawing waste.",
  },
  {
    id: "3",
    head: "",
    house: "left",
    pdf: "https://drive.google.com/file/d/1yv2MQaWwZolT02GicJ993G2ULaoWlmkT/view?usp=drive_link",
    content:
      "In 2021, Dr. K. Aarthi was granted a patent for Manual-15CEC80, which focuses on the design and drawing of steel and RCC (Reinforced Concrete) structures, incorporating innovative software applications.",
  },
  {
    id: "4",
    head: "",
    house: "right",
    pdf: "https://drive.google.com/file/d/1gTXEGkQLlQr_c11c6bEilSshFh0Kkx-4/view?usp=drive_link",
    content:
      "In 2021, a patent was granted to Dr. C. Vijayaprabha for the creation of the 15CEC76 Concrete and Highway Laboratory Manual.",
  },
];

const civ_stud_achivement =[
  {
    'id':'1',
    'heading':"Raveena (91762211042)",
    'sub-head':"Batch : 2022-2026",
    'pic':ravena,
    link: "https://drive.google.com/file/d/12KX9ldS2rRIhiO0kDMwrHHKPqKjzaItF/view?usp=sharing",
    'content_1':'',
    'content_2':"Raveena of II year Civil (Batch 2022- 2026) has represented Anna University in the All India Inter-University Karate tournament held between 16.03.3034 and 19.03.2024 at Panjab University, Chandigarh"
},

{
  
   id: "2",
    heading: " GATE Passed Students 2024",

    "sub-head":
    "The following students have cleared and attained GATE score.",
    
    pic: gate_cover,
    content_1: "",
    content_2:    "We proudly congratulates our outstanding students, Kishore S. (2011022), Nagarajan L. (2011031), Nivetha S. (2011033), and Abiksha J. (2111001), for successfully clearing the GATE exam this year. Your exceptional dedication and hard work have led to this remarkable achievement. We are incredibly proud of your success and wish you all the best in your future endeavors. May you continue to excel and achieve great heights in your academic and professional journeys.",

},
{
  id: "3",
  heading: "NPTEL Online Certification",
  "sub-head":
    "Abinaya.S has successfully completed the  NPTEL online certification course 'Introduction in Engineering Seismology'.",
  pic: nptl_cover,
  content_1: "",
  content_2: "",


}
];



const civ_lib_data = [
  {
    "S.No": 1,
    "ACCE.No": 19758,
    TITLE: "Surveying",
    AUTHOR: "Punmia (B.C)",
  },
  {
    "S.No": 2,
    "ACCE.No": 23636,
    TITLE: "Foundation analysis and Design",
    AUTHOR: "Bowles(E)\nJoseph",
  },
  {
    "S.No": 3,
    "ACCE.No": 20679,
    TITLE: "Reinforced concrete Design",
    AUTHOR: "Mallick (S.K)\nRengasamy(K.S)",
  },

  {
    "S.No": 4,
    "ACCE.No": 23599,
    TITLE: "Intermediate-Structural Analysis",
    AUTHOR: "Wang (C.K)",
  },
  {
    "S.No": 5,
    "ACCE.No": 26371,
    TITLE: "Optimization theory and applications",
    AUTHOR: "S.S. Rao",
  },
  {
    "S.No": 6,
    "ACCE.No": 21505,
    TITLE: "Testing of Metalic Materials",
    AUTHOR: "Suryanarayanan (AVK)",
  },
  {
    "S.No": 7,
    "ACCE.No": 22155,
    TITLE: "Hydrology for Engineering",
    AUTHOR: "Linsley\nKohler\nPaulhus",
  },

  {
    "S.No": 8,
    "ACCE.No": 26366,
    TITLE: "Standard Hand book for Civil Engineers",
    AUTHOR: "S. Merritt",
  },
  {
    "S.No": 9,
    "ACCE.No": 27268,
    TITLE: "Soil mechanics and Foundation Engineering",
    AUTHOR: "R. Kaniraj",
  },
  {
    "S.No": 10,
    "ACCE.No": 24549,
    TITLE: "Planing",
    AUTHOR: "Mills (Edward).D",
  },
  {
    "S.No": 11,
    "ACCE.No": 25353,
    TITLE: "Engineering Hydrology",
    AUTHOR: "Wilson",
  },
  {
    "S.No": 12,
    "ACCE.No": 23825,
    TITLE: "Geotechnical Engineering",
    AUTHOR: "Cernica(N)",
  },
  {
    "S.No": 13,
    "ACCE.No": 24958,
    TITLE: "Design of Reinforced concrete Structures",
    AUTHOR: "Dayaratnam(P)",
  },
  {
    "S.No": 14,
    "ACCE.No": 25381,
    TITLE: "Building Construction",
    AUTHOR: "Sushil Kumar",
  },
  {
    "S.No": 15,
    "ACCE.No": 23841,
    TITLE: "Foundations, Retaining and Earth structures",
    AUTHOR: "Tschebottarioff",
  },
  {
    "S.No": 16,
    "ACCE.No": 24537,
    TITLE: "Drilled piers and caissons II",
    AUTHOR: "Baker (CLYDE)",
  },
  {
    "S.No": 17,
    "ACCE.No": 21443,
    TITLE: "Analytical and computer methods in foundation engineering",
    AUTHOR: "Bowles (Joseph. E)",
  },
  {
    "S.No": 18,
    "ACCE.No": 23595,
    TITLE: "Flow through open channels",
    AUTHOR: "Rangaraju (K.G)",
  },
  {
    "S.No": 19,
    "ACCE.No": 18257,
    TITLE: "Design of reinforced concrete",
    AUTHOR: "M.C. Cormac. C.(Jack)",
  },
  {
    "S.No": 20,
    "ACCE.No": 18786,
    TITLE: "Waste water systems Engineering",
    AUTHOR: "Parker (Homer.W)",
  },
  {
    "S.No": 21,
    "ACCE.No": 23571,
    TITLE: "Planning and development of towns",
    AUTHOR: "Gupta (RG)",
  },
  {
    "S.No": 22,
    "ACCE.No": 27027,
    TITLE: "Waste water Engineering Treatment disposal",
    AUTHOR: "Metcalf and Eddy. INC",
  },
  {
    "S.No": 23,
    "ACCE.No": 20893,
    TITLE: "Calculation design and testing of Reinforced concrete",
    AUTHOR: "Rao (K.L)",
  },
  {
    "S.No": 24,
    "ACCE.No": 20416,
    TITLE: "Plain and Reinforced Concrete volume II",
    AUTHOR: "Jaikrishna and Jain(O.P)",
  },
  {
    "S.No": 25,
    "ACCE.No": 21948,
    TITLE: "Modern foundations",
    AUTHOR: "Kurian (Nainan.P)",
  },
  {
    "S.No": 26,
    "ACCE.No": 25355,
    TITLE: "Civil Engineering Materials",
    AUTHOR: "Jackson",
  },
  {
    "S.No": 27,
    "ACCE.No": 23801,
    TITLE: "Engineering Mechanics",
    AUTHOR: "Timoshenko and Young",
  },
  {
    "S.No": 28,
    "ACCE.No": 24634,
    TITLE: "An Introduction to Mechanics",
    AUTHOR: "Kleppner\nKolenkon",
  },

  {
    "S.No": 29,
    "ACCE.No": 25358,
    TITLE: "Structural Mechanics",
    AUTHOR: "Durka\nMorgen etc",
  },

  {
    "S.No": 30,
    "ACCE.No": 26247,
    TITLE: "Elementary Structural Analysis",
    AUTHOR: "Norris- Wilbur-Utku",
  },
  {
    "S.No": 31,
    "ACCE.No": 26983,
    TITLE: "Reinforced Concrete Structures",
    AUTHOR: "R.Park and T.Paulay",
  },
  {
    "S.No": 32,
    "ACCE.No": 22152,
    TITLE: "Applied Mechanics of Materials",
    AUTHOR: "Shigley (J.E)",
  },
  {
    "S.No": 33,
    "ACCE.No": 27692,
    TITLE: "Finite element procedures in Engineering Analysis",
    AUTHOR: "Bathe",
  },
  {
    "S.No": 34,
    "ACCE.No": 27782,
    TITLE: "Limit state Design",
    AUTHOR: "Dr. Ramachandra",
  },
  {
    "S.No": 35,
    "ACCE.No": 20177,
    TITLE: "Theory of Elasticity",
    AUTHOR: "Sadhu singh D.R",
  },
  {
    "S.No": 36,
    "ACCE.No": 26989,
    TITLE: "Ground water",
    AUTHOR: "H.M. Ragunath",
  },
  {
    "S.No": 37,
    "ACCE.No": 22262,
    TITLE: "Design and Construction of concrete shell roofs",
    AUTHOR: "G.S. Ramasamy",
  },
  {
    "S.No": 38,
    "ACCE.No": 20398,
    TITLE: "Design of steel and timber structures",
    AUTHOR: "S.Ramamrutham",
  },
  {
    "S.No": 39,
    "ACCE.No": 19745,
    TITLE: "Surveying vol.I",
    AUTHOR: "Dr. B.C. Punmia",
  },
  {
    "S.No": 40,
    "ACCE.No": 22956,
    TITLE: "Elements of Strength of materials",
    AUTHOR: "Timoshenko/ Young",
  },
  {
    "S.No": 41,
    "ACCE.No": 27575,
    TITLE: "Modern Geotechnical Engineering",
    AUTHOR: "Alam singh",
  },
  {
    "S.No": 42,
    "ACCE.No": 25163,
    TITLE: "The Finite Element method",
    AUTHOR: "Zinkiewicz (O.C)",
  },
  {
    "S.No": 43,
    "ACCE.No": 24636,
    TITLE: "An Introduction to the Finite Element method",
    AUTHOR: "Reddy (J.N)",
  },
  {
    "S.No": 44,
    "ACCE.No": 22148,
    TITLE: "Experimental Stress Analysis",
    AUTHOR: "Dally (J) Williams",
  },
  {
    "S.No": 45,
    "ACCE.No": 25691,
    TITLE: "Design of Steel Structures",
    AUTHOR: "Kazimi. S. M.A and Jindal R.S",
  },
  {
    "S.No": 46,
    "ACCE.No": 19754,
    TITLE: "Surveying vol II",
    AUTHOR: "Punmia (B.C)",
  },
  {
    "S.No": 47,
    "ACCE.No": 26246,
    TITLE: "Reinforced Concrete Design",
    AUTHOR: "S.N. Sinha",
  },
  {
    "S.No": 48,
    "ACCE.No": 21429,
    TITLE: "Dynamics of Structures",
    AUTHOR: "Clough (Ray.W) and Penzien (Joseph)",
  },
  {
    "S.No": 49,
    "ACCE.No": 24117,
    TITLE: "Pile foundation analysis and design",
    AUTHOR: "Poulos. Davis",
  },
  {
    "S.No": 50,
    "ACCE.No": 26993,
    TITLE: "Water pollution and Management",
    AUTHOR: "C.K. Varshney",
  },
  {
    "S.No": 51,
    "ACCE.No": 16930,
    TITLE: "Highway Engineering",
    AUTHOR: "S.K. Khanna, C.E.G. Justo",
  },
  {
    "S.No": 52,
    "ACCE.No": 24092,
    TITLE: "The treatment of Indusrial wastes",
    AUTHOR: "Bessliqre, Schwartz",
  },
  {
    "S.No": 53,
    "ACCE.No": 27007,
    TITLE: "Air Pollution",
    AUTHOR: "M.N. Rao, H.V.N. Rao",
  },
  {
    "S.No": 54,
    "ACCE.No": 24942,
    TITLE: "Microbiology for Environmental Scientists and Engineers",
    AUTHOR: "Elizabath (T) Gaudy",
  },
  {
    "S.No": 55,
    "ACCE.No": 25349,
    TITLE: "A Geology for Engineers",
    AUTHOR: "Blyth/  Defreitas",
  },
  {
    "S.No": 56,
    "ACCE.No": 27300,
    TITLE: "Pollution control in process industries",
    AUTHOR: "Sp. Maharajan",
  },
  {
    "S.No": 57,
    "ACCE.No": 26991,
    TITLE: "Soil Mechanics",
    AUTHOR: "T.William Lambe\nV.Whiteman",
  },
  {
    "S.No": 58,
    "ACCE.No": 20289,
    TITLE: "Reinforced concrete Structures vol. II",
    AUTHOR: "Punmia (B.C)",
  },
  {
    "S.No": 59,
    "ACCE.No": 20285,
    TITLE: "Reinforced concrete Structures vol.I",
    AUTHOR: "Punmia (B.C)",
  },
  {
    "S.No": 60,
    "ACCE.No": 23628,
    TITLE: "Theory of Structures",
    AUTHOR: "Timoshenko and D.H. Young",
  },
  {
    "S.No": 61,
    "ACCE.No": 26982,
    TITLE: "Advanced Mechanics of Materials",
    AUTHOR: "P. Boresi, M. Side Bottom",
  },
  {
    "S.No": 62,
    "ACCE.No": 25480,
    TITLE: "Design of concrete mixes",
    AUTHOR: "Krishnaraju (N)",
  },
  {
    "S.No": 63,
    "ACCE.No": 22102,
    TITLE: "Fluid Mechanics for Engineering Technology",
    AUTHOR: "Granet (Irving)",
  },
  {
    "S.No": 64,
    "ACCE.No": 25396,
    TITLE: "Finite Element Analysis",
    AUTHOR: "Krishnamoorthy (C.S)",
  },
  {
    "S.No": 65,
    "ACCE.No": 23957,
    TITLE: "The testing of Engineering Materials",
    AUTHOR: "Davis (H.E) etc",
  },
  {
    "S.No": 66,
    "ACCE.No": 21012,
    TITLE: "Structural Mechanics",
    AUTHOR: "Palmer c. Andrew",
  },
  {
    "S.No": 67,
    "ACCE.No": 25861,
    TITLE: "Introduction to Mechanics of solid",
    AUTHOR: "P. Popov",
  },
  {
    "S.No": 68,
    "ACCE.No": 27283,
    TITLE: "Fluid Mechanics and Hydraulics",
    AUTHOR: "B. Evetti Chengliu",
  },
  {
    "S.No": 69,
    "ACCE.No": 20253,
    TITLE: "Traffic Engineering and Transport Planning",
    AUTHOR: "Kadiyali (L.R)",
  },
  {
    "S.No": 70,
    "ACCE.No": 27861,
    TITLE: "Hydrology",
    AUTHOR: "H.M. Raghunath",
  },
  {
    "S.No": 71,
    "ACCE.No": 24087,
    TITLE: "Foundation of optimization",
    AUTHOR: "Beightler. Wilde",
  },
  {
    "S.No": 72,
    "ACCE.No": 26161,
    TITLE: "Bridge Engineering",
    AUTHOR: "S.Ponnuswamy",
  },
  {
    "S.No": 73,
    "ACCE.No": 27853,
    TITLE: "Introduction to Engineering materials",
    AUTHOR: "B.K. Agarwal",
  },
  {
    "S.No": 74,
    "ACCE.No": 27775,
    TITLE: "Surveying vol. I",
    AUTHOR: "Dr. K.R. Arora",
  },
  {
    "S.No": 75,
    "ACCE.No": 23565,
    TITLE: "Irrigation theory and Practice",
    AUTHOR: "Michel (A.M)",
  },
  {
    "S.No": 76,
    "ACCE.No": 26576,
    TITLE: "Fluid Mechanics",
    AUTHOR: "L. Streeter",
  },
  {
    "S.No": 77,
    "ACCE.No": 23611,
    TITLE: "Mechanics of Engineers Dynamics",
    AUTHOR: "Beer/ Johnson",
  },
  {
    "S.No": 78,
    "ACCE.No": 23594,
    TITLE: "Structural Analysis",
    AUTHOR: "Gupta S.B",
  },
  {
    "S.No": 79,
    "ACCE.No": 26985,
    TITLE: "Design of Prestressed concrete structures",
    AUTHOR: "T.Y. Lin- Nedh. Burns",
  },
  {
    "S.No": 80,
    "ACCE.No": 23616,
    TITLE: "Mechanics for Engineers Statics",
    AUTHOR: "Beer (P) and Johnston",
  },
  {
    "S.No": 81,
    "ACCE.No": 23626,
    TITLE: "Principles of Fluid Mechanics",
    AUTHOR: "Natarajan M.K",
  },
  {
    "S.No": 82,
    "ACCE.No": 20437,
    TITLE: "Examples of the Design of Building",
    AUTHOR: "Renold (Charles. E) and Steed Man(Jamesic)",
  },
  {
    "S.No": 83,
    "ACCE.No": 27670,
    TITLE: "Numerical methods in Geo mechanics",
    AUTHOR: "C.S. Dasai",
  },
  {
    "S.No": 84,
    "ACCE.No": 27580,
    TITLE: "Soil Stabilization in Engineering practice",
    AUTHOR: "S.R.  Mehra, H.L.Uppal",
  },
  {
    "S.No": 85,
    "ACCE.No": 22270,
    TITLE: "Foundation Engineering",
    AUTHOR: "Peck, etc",
  },
  {
    "S.No": 86,
    "ACCE.No": 22133,
    TITLE: "Building Construction",
    AUTHOR: "S.C. Rangwala",
  },
  {
    "S.No": 87,
    "ACCE.No": 27569,
    TITLE: "Shell and Spatial structures Engineering",
    AUTHOR: "Carneiro, Ferrante, etc",
  },
  {
    "S.No": 88,
    "ACCE.No": 26250,
    TITLE: "Structural Analysis",
    AUTHOR: "I.Laursen",
  },
  {
    "S.No": 89,
    "ACCE.No": 27588,
    TITLE: "Geotextile and Geomembranes",
    AUTHOR: "Veldhuijzen vanzanten",
  },
  {
    "S.No": 90,
    "ACCE.No": 27571,
    TITLE: "Site investigation practice",
    AUTHOR: "Michel D. Joyce",
  },
  {
    "S.No": 91,
    "ACCE.No": 27589,
    TITLE: "Field instrumentation in Geotechnical Engineering",
    AUTHOR: "T.H. Hanna",
  },
  {
    "S.No": 92,
    "ACCE.No": 19337,
    TITLE: "Methods of foundation Engineering vol.24",
    AUTHOR: "Bazant.Z",
  },
  {
    "S.No": 93,
    "ACCE.No": 24559,
    TITLE: "Advanced Soil Mechanics",
    AUTHOR: "Das ( B. Raja.M)",
  },
  {
    "S.No": 94,
    "ACCE.No": 5836,
    TITLE: "Principles of Engineering Geology and Geotechnics",
    AUTHOR: "Krynine Dimitri(P)",
  },
  {
    "S.No": 95,
    "ACCE.No": 20565,
    TITLE: "Earth science and the Environment",
    AUTHOR: "Ordway (Richand)",
  },
  {
    "S.No": 96,
    "ACCE.No": 24539,
    TITLE: "Handbook of Geology in Cvil Engineering",
    AUTHOR: "Legget Robert",
  },
  {
    "S.No": 97,
    "ACCE.No": 19320,
    TITLE: "Principles of Physical Geology",
    AUTHOR: "Strahler Arthur (N)",
  },
  {
    "S.No": 98,
    "ACCE.No": 18628,
    TITLE: "Encyclopaedia of the Geological Sciences",
    AUTHOR: "Lapedes Daniel (N)",
  },
  {
    "S.No": 99,
    "ACCE.No": 28261,
    TITLE: "Mechanics of Materials",
    AUTHOR: "Gere James(M)",
  },
  {
    "S.No": 100,
    "ACCE.No": 27886,
    TITLE: "Analysis of Structures",
    AUTHOR: "V.N. Vazirani and M.M. Ratwami",
  },
  {
    "S.No": 101,
    "ACCE.No": 31966,
    TITLE: "The Nautical Almanac 1995",
    AUTHOR: "Hermajesty's Nautical Almanac office",
  },
  {
    "S.No": 102,
    "ACCE.No": 31965,
    TITLE: "The nautical Almanac 1994",
    AUTHOR: "Hermajesty's Nautical Almanac office",
  },
];

const civil_study_matetial=[
  {
    'id':'1',
    'program':'Notes link',
    'pdf':'https://drive.google.com/file/d/1qUsgu4k0SfUpZgnwFIaDB1LGwRixw8El/view?usp=sharing'
},
];

const civ_place_24_zoho=[
  {
    sno:1,
    point:"Fathima Rizwana"
  },
];

const civ_place_24_lt=[
  {
    sno:1,
    point:"Saranraj R"
  },
];

const civ_place_24_im=[
  {
    sno:1,
    point:"Gokulavasan"
  },
  {
    sno:2,
    point:"Gokulnath"
  },
];

const civ_place_24_conserve=[
  {
    sno:1,
    point:"Kasthuri"
  },
];

const civ_place_24_pinnacle=[
  {
    sno:1,
    point:"Balakumaran N"
  },
  {
    sno:2,
    point:"Dineshkumar A"
  },
  {
    sno:3,
    point:"Narayanakarthick M"
  },
  {
    sno:4,
    point:"Pavithra S"
  },
  {
    sno:5,
    point:"Selvakumar A"
  },
  {
    sno:6,
    point:"Keerthika G"
  },
  {
    sno:7,
    point:"Janarthini B"
  },
];

const civ_place_lt=[
  {
    sno:1,
    point:"C Krishnaraju"
  },
  {
    sno:2,
    point:"A.Janith Marianathan"
  },
];

const civ_place_hexagon=[
  {
    sno:1,
    point:"Priyadharshini V"
  },
  {
    sno:2,
    point:"Muthu R"
  },
];

const civ_place_aarbee=[
  {
    sno:1,
    point:"Krishnaveni"
  },
  {
    sno:2,
    point:"Santhya V"
  },
  {
    sno:3,
    point:"Manibharathi C"
  },
];

const civ_place_pinnacle=[
  {
    sno:1,
    point:"Eyalarasan M"
  },
  {
    sno:2,
    point:"Selvakumar S"
  },
  {
    sno:3,
    point:"Selvarathinam VT"
  },
  {
    sno:4,
    point:"Dharshinimai LB"
  },
  {
    sno:5,
    point:"Amuthan K"
  },
  {
    sno:6,
    point:"Nishanthini A"
  },
  {
    sno:7,
    point:"Sriram"
  },
  {
    sno:8,
    point:"Sneka S"
  },
];

const mag_data=[
  {
    'id':'5',
    'program':'Magazine 2024',
    'pdf':'https://drive.google.com/file/d/1ROP_ak5Or3dnNIaU5Fz9PuZZdDiBDqzD/view?usp=sharing'
  },
  {
    'id':'3',
    'program':'Newsletter Oct 2023',
    'pdf':'https://drive.google.com/file/d/1cD33AhRGQoBjQH9S1ned3_SPaS-r95ez/view?usp=drive_link'
  },
  {
    'id':'4',
    'program':'Newsletter Sep 2023',
    'pdf':'https://drive.google.com/file/d/1wTGRTZwAF987HStZL-uPCkeJ3vOrJrcY/view?usp=drive_link'
  },
  {
    'id':'1',
    'program':'Cesar Magazine 2021',
    'pdf':'https://drive.google.com/file/d/103v3bTK5ynXUzOxk5hzzr_upcQLJKJcc/view?usp=sharing'
  },
  {
    'id':'2',
    'program':'Cesar Magazine 2020',
    'pdf':'https://drive.google.com/file/d/1wvRtm8_BDqIFSj3oSlI7clnOANdDVkLk/view?usp=sharing'
  },
]

const intern_data=[
  {
    'id':'1',
    'program':'Internship 2023-2024',
    'pdf':'https://drive.google.com/file/d/1CLuzMgo3roJNBq1WZ_fk4DbxjwNQ-4hB/view?usp=drive_link'
  },
  {
    'id':'2',
    'program':'Internship 2022-2023',
    'pdf':'https://drive.google.com/file/d/1IIDeJbROJ94K_oo7UstfNNmwnM6DURgL/view?usp=drive_link'
  }
]

const mou_data=[
  {
    'id':'1',
    'program':'Mou - Alagappan             ',
    'pdf':'https://drive.google.com/file/d/1TItIYGBOuqZurmqYxvnAS3w3Ou783mE5/view?usp=drive_link'
  },
  {
    'id':'2',
    'program':'Memorandum of understanding 1',
    'pdf':'https://drive.google.com/file/d/1jcufj4gPIj1kFXJ4o6l-_TxzQ_bPnC4t/view?usp=drive_link'
  }
]

const civ_indus_slide=[
     slide1,slide2,slide3
]

const scholar_info1=[
  {
    'sno':"1",
    'name':"M Sasi Rekha",
    'yr':"July 2018",
    'title':"Experimental Investigation of fly ash and GGBS based Geopolymer concrete cured at ambient temperature",
    'status':"Completed",
  },
  {
    'sno':"2",
    'name':"R Jagadheeswari",
    'yr':"July 2018",
    'title':"Experimental investigation of using aluminium based binder compound to make carbon negative concrete",
    'status':"Ongoing",
  },
  {
    'sno':"3",
    'name':"G.Elavarasi",
    'yr':"July 2022",
    'title':"Experimental investigation on Pervious Concrete",
    'status':"Ongoing",
  },
  {
    'sno':"4",
    'name':"S.Vigneshkannan",
    'yr':"July 2021",
    'title':"Experimental and analytical investigation of flexural behaviour of reinforced concrete beam with Calatropis gigantean fibre composite as shear reinforcement",
    'status':"Ongoing",
  },
];
const scholar_info2=[
  {
    'sno':"1",
    'name':"T.Chockalingam",
    'yr':"July 2018",
    'title':"Investigation on Structural Behavior of Pervious Concrete Slabs - A Sustainable Material for Pavements",
    'status':"Ongoing",
  },
  {
    'sno':"2",
    'name':"V.Ragavan",
    'yr':"July 2018",
    'title':"Development of engineered high-performance concrete incorporated with Polyvinyl Alcohol Fibre (PVA)",
    'status':"Ongoing",
  },
  {
    'sno':"3",
    'name':"P.Janaki",
    'yr':"2019",
    'title':"Evaluation of Geopolymer coatings for corrosion protection of steel reinforcement in concrete",
    'status':"Completed",
  },
  {
    'sno':"4",
    'name':"R.Thenmozhi",
    'yr':"July 2018",
    'title':"Experimental investigation on performance evaluation of coatings in geopolymer concrete",
    'status':"Ongoing",
  },
  {
    'sno':"5",
    'name':"V.Ramya",
    'yr':"July 2019",
    'title':"Experimental Study on the Behaviour of Flexible Concrete Incorporating AR Glass Fiber And Industrial By-Product",
    'status':"Ongoing",
  },
];
const scholar_info3=[
  {
    'sno':"1",
    'name':"Vasu Nithyanandam N",
    'yr':"2022",
    'title':"Use of demolished concrete waste and coconut Shells as coarse aggregate for bacterial Concrete",
    'status':"Ongoing",
  },
  {
    'sno':"2",
    'name':"Muruganantham R",
    'yr':"2022",
    'title':"Response of wall panels Under blast loading",
    'status':"Ongoing",
  },
];
const scholar_info4=[
  {
    'sno':"1",
    'name':"S.Nagalakshmi",
    'yr':"July 2018",
    'title':"Performance Evaluation of Membrane Bio reactor in treating Domestic Wastewater",
    'status':"Ongoing",
  },
  {
    'sno':"2",
    'name':"T.Swathi",
    'yr':"July 2018",
    'title':"Experimental studies on modified upflow anaerobic sludge blanket reactor in treating wastewater",
    'status':"Ongoing",
  },
  {
    'sno':"3",
    'name':"C.Nivedhitha",
    'yr':"Jul 2018",
    'title':"Removal of Toxic Elements from Wastewater by Adsorption",
    'status':"Ongoing",
  },
];
const scholar_info5=[
  {
    'sno':"1",
    'name':"R.Vivekanandan",
    'yr':"Jan 2020",
    'title':"Carbon fibre as shear reinforcement in RC beams",
    'status':"Ongoing",
  },
  {
    'sno':"2",
    'name':"A.S.Sathishkumar",
    'yr':"Jan 2020",
    'title':"Effect of Kenaf Fiber in Bacterial Concrete",
    'status':"Ongoing",
  },
  {
    'sno':"3",
    'name':"M.Banupriya",
    'yr':"Jan 2021",
    'title':"Cold Formed Steel beams with Concrete infills",
    'status':"Ongoing",
  },
  {
    'sno':"4",
    'name':"R.L.Lija",
    'yr':"Jan 2017",
    'title':"Self Curing concrete with Super Absorbent polymers",
    'status':"Ongoing",
  },
];
const civ_research_info=[
  {
    'sno':"1",
    'author':"Senthamizhan M, Balamurugan.P, Shunmugapriya K",
    'title':"Morphometric analysis of thadayampatti watershed, Madurai district, Tamilnadu, India",
    'name':"International journal of engineering development and research ",
    'yr':"July-16",
    'vol':"Volume:4 ; Issue:3",
    'fac':"7.37",
    'Doi':"https://www.ijedr.org/papers/IJEDR1603018.pdf"
  },
  {
    'sno':"2",
    'author':"Gowtham.B , Pauline.S",
    'title':"Performance Evaluation studies of Fenton Oxidation on Different Dyes and its Optimization of Experimental Parameters",
    'name':"Journal of Materials and Environmental Sciences ",
    'yr':"Jan-20",
    'vol':"Volume 11, ISsue :2",
    'fac':"0.75",
    'Doi':"https://www.jmaterenvironsci.com/Document/vol11/vol11_N2/JMES-2020-11-20-Gowtham.pdf"
  },
  {
    'sno':"3",
    'author':"Balamurugan Panneerselvam & Suresh Kumar Paramasivam & Shankar Karuppannan & Nagavinothini Ravichandran & Pauline Selvaraj",
    'title':"A GIS-based evaluation of hydrochemical characterisation of groundwater in hard rock region, South Tamil Nadu, India",
    'name':"Arabian Journal of Geosciences",
    'yr':"Aug-20",
    'vol':"Volume 13",
    'fac':"1.534",
    'Doi':"https://doi.org/10.1007/s12517-020-05813-w"
  },
  {
    'sno':"4",
    'author':"SR Sumathy,Sasi Rekha, Jagadheeswari , Arunprasath",
    'title':"Durability properties of copper slag and fly ash based concrete for a sustainable environment",
    'name':"Materialstoday: Proceedings ",
    'yr':"Oct-01",
    'vol':"Volume 37; Issue 2",
    'fac':"1.3",
    'Doi':"https://doi.org/10.1016/j.matpr.2020.08.490"
  },
  {
    'sno':"5",
    'author':"SR Sumathy,Sasi Rekha",
    'title':"A study on cement-free gepolymer concrete incorporated with industrial waste cured at open environment for different molarities of sodium hydroxide",
    'name':"Global NEST Journal, vol 23,No X,pp 1-10",
    'yr':"-",
    'vol':"Volume 23",
    'fac':"-",
    'Doi':"Print ISSN: 1790-7632"
  },
  {
    'sno':"6",
    'author':"S M Kavitha, Dr G Venkatesan, Siva A, Erick Saavedra Flores",
    'title':"Mechanical and flexural performance of self compacting concrete with natural fiber",
    'name':"Revista de la Construcción. Journal of Construction",
    'yr':"Aug,2020",
    'vol':"Vol.19, Issue no.2",
    'fac':"0.21",
    'Doi':"Electronic ISSN: 2241-777X"
  },
  {
    'sno':"7",
    'author':"P balamurugan, S.Pauline,M.Kirubarakaran, M.Thomas",
    'title':"Assessment of inverse fluidized bed reactor on thetreatment efficiency of distillery spent wash water",
    'name':"International Journal of Environmental Science and Technology",
    'yr':"Sep,2021",
    'vol':"-",
    'fac':"2.86",
    'Doi':"https://doi.org/10.1007/s13762-021-03650-2"
  },
  {
    'sno':"8",
    'author':"Alagar Leelavathi& Sudalaimani, K",
    'title':"Study on self-compacting concrete with sustainable materials",
    'name':"Polish Journal of Environmental Studies",
    'yr':"Oct,2021",
    'vol':"vol. 30, no. 6, pp. 5079-5088",
    'fac':"1.699",
    'Doi':"DOI: https://doi.org/10.15244/pjoes/135827"
  },
  {
    'sno':"9",
    'author':"Leelvathi A, Mohammed Uvais‘",
    'title':"Numerical Investigation on the Behaviour of Beam-column Joints under controlled corrosion levels",
    'name':"Journal of structural engineering , its applications and Analysis",
    'yr':"Oct,2021",
    'vol':"vol:4,issue:3,2021",
    'fac':"-",
    'Doi':"http://hbrppublication.com/OJS/index.php/JSEAA/article/view/2103"
  },
  {
    'sno':"10",
    'author':"Vijayaprabha Chakrawarthi , Siva Avudaiappan , Mugahed Amran , Brindha Dharmar , Leon Raj Jesuarulraj , Roman Fediuk , Radhamanohar Aepuru , Nikolai Vatin and Erick Saavedra Flores",
    'title':"Impact Resistance of Polypropylene Fibre-Reinforced Alkali–Activated Copper Slag Concrete",
    'name':"Materials",
    'yr':"Dec-21",
    'vol':"vol,14 issue 24,pp:1 -27",
    'fac':"3.748",
    'Doi':"https://doi.org/10.3390/ma14247735"
  },

  {
    'sno':"11",
    'author':"Harish S., Meiaraj C. and Vijayaprabha C",
    'title':"Assessment of Ground water quality maping along the banks of the Noyyal river in Tirupur district",
    'name':"International Journal of Current Engineering and Technology",
    'yr':"Aug-21",
    'vol':"vol.9 PP.120-123",
    'fac':"-",
    'Doi':"E-ISSN 2277 – 4106, P-ISSN 2347 – 5161"
  },
  {
    'sno':"12",
    'author':"Karmugilan , Vijayaprabha C",
    'title':"Experimental and Numerical investigation on Cold-Formed Steel builtup beams",
    'name':"International Journal of Current Engineering and Technology",
    'yr':"Aug-21",
    'vol':"vol.9 PP.129-132",
    'fac':"-",
    'Doi':"E-ISSN 2277 – 4106, P-ISSN 2347 – 5161"
  },
  {
    'sno':"13",
    'author':"Selvaraj S and Vijayaprabha",
    'title':"Experimental study of concrete with partial replacement of fine aggregate by using iron scale",
    'name':"International Journal of Current Engineering and Technology",
    'yr':"Aug-21",
    'vol':"vol.9 PP.133-137",
    'fac':"-",
    'Doi':"E-ISSN 2277 – 4106, P-ISSN 2347 – 5161"
  },
  {
    'sno':"14",
    'author':"Vijayaprabha Chakrawarthi , Brindha Dharmar, Siva Avudaiappan, Mugahed Amran, Erick Saavedra Flores , Mohammad Ayaz Alam , Roman Fediuk, Nikolai Ivanovich Vatin and Raizal S. M. Rashid",
    'title':"Destructive and Non-Destructive Testing of the Performance of Copper Slag Fiber-Reinforced Concrete",
    'name':"Materials ",
    'yr':"Jun-22",
    'vol':"vol.15, issue 13, pp1-24",
    'fac':"-",
    'Doi':"https://doi.org/10.3390/ma15134536"
  },
  {
    'sno':"15",
    'author':"Sumathy Raju, Jagadheeswari Rathinam , Brindha Dharmar , Sasi Rekha , Siva Avudaiappan , Mugahed Amran , Kseniia Iurevna Usanova , Roman Fediuk , Pablo Guindos and Ramkumar Velayutham Ramamoorthy 1",
    'title':"Cyclically Loaded Copper Slag Admixed Reinforced Concrete Beams with Cement Partially Replaced with Fly Ash",
    'name':"Materials",
    'yr':"Apr-22",
    'vol':" Volume 15; Issue 9",
    'fac':"-",
    'Doi':" doi: 10.3390/ma15093101"
  },
  {
    'sno':"16",
    'author':"S.Mariraj Mohan, S.Nagalakshmi",
    'title':"A review on aerobic self-forming dynamic memebane bioreactor:Formation,performance,fouling and cleaning",
    'name':"Journal of water process Engineering",
    'yr':"Jul-20",
    'vol':"Vol 37, article no:101541",
    'fac':"7.34",
    'Doi':"https://doi.org/10.1016/j.jwpe.2020.101541"
  },
  {
    'sno':"17",
    'author':"G.Venkatesan, S.Pauline",
    'title':"Influence of Chemical, Electrochemical Exfoliation, Hydrophilic, and Hydrophobic Binder on the Sorption Capacity of Graphene in Capacitive Deionization",
    'name':"Journal of Environmental Engineering",
    'yr':"Jul-22",
    'vol':"Vol.148, Issue no: 7",
    'fac':"1.657",
    'Doi':"https://doi.org/10.1061/(ASCE)EE.1943-7870.0001999"
  },
  {
    'sno':"18",
    'author':"Aarthi K , Siva Avudaiappan, Mugahed Amran , Pablo Guindos, Nikolai Ivanovich Vatin, Roman Fediuk,",
    'title':"Retrofitting RC beams using high-early strength alkali-activated concrete",
    'name':"ELSEVIER Case Studies in Construction Materials 17 (2022) e01194, Available online 27 May 2022",
    'yr':"May-22",
    'vol':"Vol 17 (2022) e01194",
    'fac':"5.06",
    'Doi':"DOI:https://doi.org/10.1016/j.cscm.2022.e01194"
  },
  {
    'sno':"19",
    'author':"Aarthi.K, Pavithran.R",
    'title':"Shear Connector Slabs Longitudinal Shear Resistance of Sandwich Panels with Truss and Trapezoidal shear connectors’",
    'name':"Mathematical Statistician and Engineering Applications",
    'yr':"Jul-22",
    'vol':"Page Number: 899 – 913, Publication Issue: Vol. 71 No. 3s (2022)",
    'fac':"-",
    'Doi':"http://philstat.org.ph"
  },
  {
    'sno':"20",
    'author':"Aarthi K., Poongothai S. P",
    'title':"Soil Stabilization with Vertical Piles Bearing Capacity and Settlement Characteristics of Cohesive Soil Reinforced with Sand, M Sand, Quarry Dust Piles",
    'name':"Mathematical Statistician and Engineering Applications",
    'yr':"Jul-22",
    'vol':"Page Number: 879 – 898, Publication Issue: Vol. 71 No. 3s (2022)",
    'fac':"-",
    'Doi':"http://philstat.org.ph5"
  },
]

const about_info=[
  {
    'id':"1",
    'point':"Recognized Research Centre of Anna University, Chennai for pursuing Ph.D. in Civil Engineering",
  },
  {
    'id':"2",
    'point':"There are 11 part time PhD scholars and 2 full time PhD scholars, pursuing their doctoral degree in this department.",
  },
  {
    'id':"3",
    'point':"The department is regularly conducting GATE coaching classes and special lectures from industrial experts for increasing the employability skills and for easy access to get PG admission.",
  },
  {
    'id':"4",
    'point':"The department is earning  10 to 15 Lakhs regularly per annum as consultancy revenue under Internal Revenue Generation.",
  },
  {
    'id':"5",
    'point':"The students are regularly undertaking projects related to the use of energy efficient materials in building construction and reducing pollution related impacts on the environment in order to promote green energy principles and clean environment.",
  },
  {
    'id':"6",
    'point':"The research works of the faculty and students are being published in reputed journals. The faculty members are publishing about 10 research papers every year.",
  },
  {
    'id':"7",
    'point':"Students are encouraged to undergo Internship under fast-track scheme to improve their placement prospects.",
  },
  {
    'id':"8",
    'point':"Soft skill training programmes offered by reputed professionals are arranged to enhance their communication and general aptitude skills.",
  },
  {
    'id':"9",
    'point':"Wi-Fi enabled internet facilities are available in the department.",
  },
  {
    'id':"10",
    'point':"Tamil Nadu government has been offering PG Assistantship Rs6000 per month for non-GATE students in order to increase the enrolment of PG students.",
  },
  {
    'id':"11",
    'point':"The students are allowed to do the online courses as per their field of interest through SWAYAM-NPTEL platform instead of professional and Open Electives.",
  },
];

const civ_publish_data=[
  {
    'id':'1',
    'program':'Publications 2021-2024',
    'pdf':'https://drive.google.com/file/d/16e9TgFTuyxjtcSb3Nb8OQF8EH_pjy6MV/view?usp=drive_link'
  },
  {
    'id':'2',
    'program':'Publications 2017-2020',
    'pdf':'https://drive.google.com/file/d/1ehk3vD503j4FZUcuVXO8K9-gK7CYaXfr/view?usp=drive_link'
  }
]

export {
  civ_dept_menu,
  civ_fac_profiles,
  civ_sup_profiles,
  civ_mission,
  civ_outcomes,
  civ_spec_outcomes,
  civ_peo,
  civ_vision,
  civ_progs,
  civ_dept_events,
  civ_labs,
  civ_awards,
  civ_patents,
  civ_stud_achivement,
  civ_lib_data,
  civil_study_matetial,
  civ_place_lt,
  civ_place_hexagon,
  civ_place_aarbee,
  civ_place_pinnacle,
  about_info,
  internal,
  scholar_info1,
  scholar_info2,
  scholar_info3,
  scholar_info4,
  scholar_info5,
  civ_research_info,
  mag_data,
  intern_data,
  mou_data,
  civ_indus_slide,
  civ_place_24_zoho,
  civ_place_24_lt,
  civ_place_24_im,
  civ_place_24_conserve,
  civ_place_24_pinnacle,
  civ_publish_data,

};
